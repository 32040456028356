import { useContext } from 'react'
import { useMoralis } from 'react-moralis'

import { WidgetContext } from 'state/WidgetContext'

export const useHeaders = () => {
  const { user } = useMoralis()
  const widgetContext = useContext(WidgetContext)

  const keys = {
    moralisSessionToken: 'x-moralis-session-token',
    cryptoServiceToken: 'x-hallway-crypto-session-token'
  }

  const moralisSessionToken = user?.get('sessionToken')
  const sessionToken = widgetContext.sessionToken

  const headers: Record<string, string> = {
    'Content-Type': 'application/json'
  }

  if (moralisSessionToken) headers[keys.moralisSessionToken] = moralisSessionToken
  if (sessionToken) headers[keys.cryptoServiceToken] = sessionToken

  return {
    keys,
    headers
  }
}
