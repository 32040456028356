import { ModelType } from '@quarkworks-inc/avatar-webkit-rendering'
import React from 'react'

import { OSCState } from 'service/osc/useOSC'
import { UserRole, WidgetError } from 'service/types'
import { NftCollection } from 'service/useNftCollections'

import { AvatarInfo } from './AvatarInfo'

export type WidgetContextWriteBlock = (draftState: WidgetContextType) => WidgetContextType | void

export type WidgetParams = {
  apiToken: string
  avatarType?: ModelType
  avatarUrl?: string
  collection?: string
  walletAddress?: string
}

export type WidgetContextType = {
  // Widget params
  params: WidgetParams
  nftCollection?: NftCollection

  // Auth
  sessionToken: string | null
  userRole: UserRole | null
  testingAddress: string | null // For admin testing purposes

  // Nft data
  // Null indicates not fetched yet
  defaultAvatars: AvatarInfo[]
  avatars: AvatarInfo[]
  currentAvatar: AvatarInfo | null
  glbIsLoading: boolean

  // Recording
  recordingDuration: number

  // Streaming
  oscState: OSCState

  // State updates are done immutably using immer produce (write) block
  // See implementation in WidgetProvider
  update: (writeBlock: WidgetContextWriteBlock) => void

  error?: WidgetError
}

export const WidgetContext = React.createContext<WidgetContextType>(null)
