import { Popover, Transition } from '@headlessui/react'
import { Fragment } from 'react'

import styles from './animatedPopover.module.scss'

export const AnimatedPopover = ({ children }) => {
  return (
    <Transition
      as={Fragment}
      enter={styles.panelEnter}
      enterFrom={styles.panelEnterFrom}
      enterTo={styles.panelEnterTo}
      leave={styles.panelLeave}
      leaveFrom={styles.panelLeaveFrom}
      leaveTo={styles.panelLeaveTo}
    >
      <Popover.Panel className={styles.panel} onKeyDown={e => e.preventDefault()} role="button" tabIndex={0}>
        {children}
      </Popover.Panel>
    </Transition>
  )
}
