import { useContext, useEffect } from 'react'
import { useMoralis } from 'react-moralis'
import Moralis from 'moralis/types'

import { config } from 'config'
import { WidgetContext } from 'state/WidgetContext'

import { useHeaders } from './useHeaders'
import { UserRole } from './types'

export type EthereumProvider = 'metamask' | 'coinbase' | 'walletConnect'

export class ProviderNotFoundError extends Error {
  constructor(private providerName: string, message?: string, options?: ErrorOptions) {
    super(message, options)
  }
}

export const useAuthService = () => {
  const widgetContext = useContext(WidgetContext)
  const { isAuthenticated, authenticate: authenticateMoralis, logout: logoutMoralis } = useMoralis()
  const { headers, keys } = useHeaders()

  /**
   * Sends an auth request to Crypto Service API. User needs to have already
   * authenticated with Moralis locally and have a session token to send.
   *
   * A 'testingAddress' can be provided to act as someone else if the user is an admin.
   * Actions will be authorized server side by checking tokens & user roles.
   */
  const authorizeWithCryptoService = async (testingAddress?: string) => {
    const body = {}
    if (testingAddress) {
      body['testAccounts'] = [testingAddress]
    }

    return fetch(`${config.cryptoServiceUrl}/auth`, {
      method: 'POST',
      headers,
      body: JSON.stringify(body)
    })
      .then(async res => {
        const sessionToken = res.headers.get(keys.cryptoServiceToken)
        const body = await res.json()

        widgetContext.update(state => {
          state.sessionToken = sessionToken
          state.userRole = body.role as UserRole
          state.testingAddress = testingAddress
        })
      })
      .catch(err => console.error('Error authorizing as admin', err))
  }

  /**
   * There are 2 steps to signing in:
   * 1) Authenticate wallet with Moralis
   * 2) Send moralis session token to crypto API and authorize with the service
   *    The second step is triggered by the useEffect() (we need useHeaders hook to be updated)
   */
  const signInWithMetaMask = () =>
    new Promise<Moralis.User>((resolve, reject) => {
      // The Moralis auth function wants you to give success/error callbacks even though
      // they return a Promise >:3 The promise errors don't work
      const ethereum = window.ethereum
      if (ethereum) {
        setEthereumProvider('metamask')

        // Prevents activation of other default wallets installed that are not MetaMask (e.g. Coinbase)
        if (!ethereum.isMetaMask) {
          throw new ProviderNotFoundError('MetaMask', 'Provider not found.')
        }

        authenticateMoralis({
          provider: 'metamask',
          signingMessage: `Hello there!\n\nClick to sign in and use your ${
            widgetContext.nftCollection?.namePlural ?? 'NFTs'
          }.\n\nThis request will not trigger a blockchain transaction or cost any gas fees.`,
          onSuccess: resolve,
          onError: reject
        })
      } else {
        throw new ProviderNotFoundError('MetaMask', 'Provider not found.')
      }
    })

  const signInWithWalletConnect = () =>
    new Promise<Moralis.User>((resolve, reject) => {
      authenticateMoralis({
        provider: 'walletconnect',
        signingMessage: `Hello there!\n\nClick to sign in and use your ${
          widgetContext.nftCollection?.namePlural ?? 'NFTs'
        }.\n\nThis request will not trigger a blockchain transaction or cost any gas fees.`,
        onSuccess: resolve,
        onError: reject
      })
    })

  const signInWithCoinbase = () =>
    new Promise<Moralis.User>((resolve, reject) => {
      // The Moralis auth function wants you to give success/error callbacks even though
      // they return a Promise >:3 The promise errors don't work
      const ethereum = window.ethereum
      if (ethereum) {
        if ('providers' in ethereum) {
          setEthereumProvider('coinbase')
        } else if (!ethereum.isCoinbaseWallet) {
          throw new ProviderNotFoundError('Coinbase', 'Provider not found.')
        }

        authenticateMoralis({
          signingMessage: `Hello there!\n\nClick to sign in and use your ${
            widgetContext.nftCollection?.namePlural ?? 'NFTs'
          }.\n\nThis request will not trigger a blockchain transaction or cost any gas fees.`,
          onSuccess: resolve,
          onError: reject
        })
      } else {
        throw new ProviderNotFoundError('Coinbase', 'Provider not found.')
      }
    })

  useEffect(() => {
    if (isAuthenticated) authorizeWithCryptoService()
  }, [isAuthenticated])

  /**
   * Sign out
   *
   * Should reset context state & sign out of moralis
   *
   */
  const signOut = async () => {
    widgetContext.update(state => {
      state.sessionToken = null
      state.userRole = null
      state.testingAddress = null
    })

    return logoutMoralis()
  }

  /**
   * Setup Ethereum Provider
   *
   * Handles Ethereum provider when you have different wallet extensions installed
   */
  const setEthereumProvider = (providerName: EthereumProvider) => {
    if (!window.ethereum?.providers) {
      return
    }

    let provider: any
    switch (providerName) {
      case 'metamask':
        provider = window.ethereum?.providers?.find(({ isMetaMask }) => isMetaMask)
        break
      case 'coinbase':
        provider = window.ethereum?.providers?.find(({ isCoinbaseWallet }) => isCoinbaseWallet)
        break
    }

    if (!provider) {
      throw new ProviderNotFoundError(providerName, `Provider not found: ${providerName}`)
    }

    window.ethereum?.setSelectedProvider(provider)
  }

  return {
    authorizeWithCryptoService,
    signInWithMetaMask,
    signInWithWalletConnect,
    signInWithCoinbase,
    signOut
  }
}
