import { useState } from 'react'
import SearchField from 'react-search-field'

import { createApi } from 'unsplash-js'

import { BackgroundColorOptions } from 'avatar/BackgroundColorOptions'
import { BackgroundOptions } from 'avatar/BackgroundOptions'

import { BackgroundItem } from './backgroundItem'

import styles from './backgrounds.module.scss'

type Props = {
  value: BackgroundOptions | BackgroundColorOptions
  options: BackgroundOptions[] | BackgroundColorOptions[]
  onChange: (background: BackgroundOptions | BackgroundColorOptions) => void
  api: ReturnType<typeof createApi>
}

export function BackgroundsPage({ value, options, onChange, api }: Props) {
  const [backgroundsList, setBackgroundsList] = useState(options)
  const search = (value: string) => {
    if (!value) {
      setBackgroundsList(BackgroundColorOptions.all)
      return
    }

    api.search
      .getPhotos({
        query: value,
        perPage: 10,
        contentFilter: 'high',
        orientation: 'landscape'
      })
      .then(result => {
        if (result.errors) {
          console.log('error occurred: ', result.errors[0])
        } else {
          console.log('got photos: ', result.response)
          const { results } = result.response
          if (results.length === 0) {
            alert('No results found! Please try again.')
          } else {
            for (let i = 0; i < results.length; i++) {
              BackgroundOptions.all_unsplash[i] = new BackgroundOptions(
                results[i].id,
                results[i].description,
                results[i].urls.thumb,
                results[i].urls.full,
                '', // Size
                results[i].links.download_location,
                results[i].user.name,
                results[i].user.links.html
              )
            }
          }
          setBackgroundsList(BackgroundColorOptions.all)
          setBackgroundsList(BackgroundOptions.all_unsplash)
        }
      })
  }
  return (
    <div className={styles.backgroundDiv}>
      <SearchField
        placeholder="Find backgrounds..."
        id="backgroundSearch"
        name="bgSearch"
        type="search"
        onEnter={search}
        onSearchClick={search}
        classNames={styles.searchBar}
      />
      <ul className={styles.backgroundList}>
        {backgroundsList.map((item, i) => {
          let isSelected = false

          if (value instanceof BackgroundColorOptions) {
            isSelected = value.hex === item.hex
          } else if (value instanceof BackgroundOptions) {
            isSelected = value.id === item.id
          }

          return (
            <BackgroundItem
              idx={i}
              key={item.hex ?? item.id}
              background={item}
              isSelected={isSelected}
              onClick={onChange}
            />
          )
        })}
      </ul>
    </div>
  )
}
