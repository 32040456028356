import cx from 'classnames'
import { useContext } from 'react'
import { useMoralis } from 'react-moralis'

import { WidgetContext } from 'state/WidgetContext'
import { AvatarInfo } from 'state/AvatarInfo'
import { ConnectWalletView } from 'components/emptyState/connectWallet'
import { WalletEmptyView } from 'components/emptyState/walletEmpty'

import { AvatarItem } from './avatarItem'

import styles from './inventory.module.scss'

export const InventoryPage = () => {
  const widgetContext = useContext(WidgetContext)
  const { isAuthenticated } = useMoralis()

  const { avatars } = widgetContext

  const onChange = (avatar: AvatarInfo) => {
    widgetContext.update(state => {
      state.currentAvatar = avatar
    })
  }

  // FTUX view
  if (!isAuthenticated && !widgetContext.params.walletAddress) {
    return (
      <div className={styles.container}>
        <ul className={cx(styles.inventoryList, styles.signedOut)}>
          {avatars.map(item => {
            return <AvatarItem key={item.keyId} avatar={item} onClick={onChange} />
          })}
        </ul>
        {/* This one is shown on mobile */}
        <div className={cx(styles.connectWallet, styles.signedOut)}>
          <ConnectWalletView />
        </div>
      </div>
    )
  }

  // Wallet is empty
  if (!avatars.length) {
    return (
      <div className={styles.walletEmpty}>
        <WalletEmptyView />
      </div>
    )
  }

  // Wallet inventory
  return (
    <div className={styles.container}>
      {avatars.length ? (
        <ul className={styles.inventoryList} style={{ overflowY: 'auto' }}>
          {avatars.map(item => {
            return <AvatarItem key={item.keyId} avatar={item} onClick={onChange} />
          })}
        </ul>
      ) : (
        <WalletEmptyView />
      )}
    </div>
  )
}
