import { useContext } from 'react'
import { useMoralis, useMoralisWeb3Api } from 'react-moralis'

import { config } from 'config'
import { AvatarInfo, avatarInfoForNft } from 'state/AvatarInfo'
import { MoralisNftInfo, NftInfo, parseNftInfo } from 'state/NftInfo'
import { WidgetContext } from 'state/WidgetContext'

import { useHeaders } from './useHeaders'

export const useNftsService = () => {
  const widgetContext = useContext(WidgetContext)
  const Web3Api = useMoralisWeb3Api()
  const { isAuthenticated, user } = useMoralis()
  const { headers } = useHeaders()

  const walletAddressParam = widgetContext.params.walletAddress
  const collectionParam = widgetContext.params.collection

  const fetchNftsList = async () => {
    let address: string

    if (user.attributes.accounts.length > 0) {
      address = user.attributes.accounts[0]
    }

    // walletAddressParam overrides wallet
    if (walletAddressParam) {
      address = walletAddressParam
    }

    // testingAddress overrides everything
    if (widgetContext.testingAddress) {
      address = widgetContext.testingAddress
    }

    // Reset when disconnected from wallet
    if ((!isAuthenticated && !walletAddressParam) || (walletAddressParam && collectionParam !== 'voids')) {
      widgetContext.update(state => {
        state.avatars = state.defaultAvatars

        // If the current avatar is not amongst the defaults, reset to the first default
        if (!state.defaultAvatars.find(a => a.keyId === state.currentAvatar?.keyId)) {
          state.currentAvatar = state.defaultAvatars[0]
        }
      })
      return
    }

    // Fetch NFTs and parse
    const result = await Web3Api.account.getNFTs({ address: address })
    let nftsRaw = result.result as MoralisNftInfo[]
    if (!nftsRaw) {
      widgetContext.update(state => {
        state.avatars = []

        // In the wallet empty state, we want to show one of the default avatars
        //
        // If the current avatar is not amongst the defaults, reset to the first default
        if (!state.defaultAvatars.find(a => a.keyId === state.currentAvatar?.keyId)) {
          state.currentAvatar = state.defaultAvatars[0]
        }
      })
      return
    }

    // Filter list by NFT symbol
    if (widgetContext.nftCollection) {
      nftsRaw = nftsRaw.filter(token => token.symbol === widgetContext.nftCollection.symbol)
    }

    const nfts: NftInfo[] = nftsRaw.map(nft => parseNftInfo(nft))

    const avatars: AvatarInfo[] = nfts.map(nft => avatarInfoForNft(nft, widgetContext.nftCollection))

    widgetContext.update(state => {
      state.avatars = avatars
      const first = avatars.length ? avatars[0] : state.defaultAvatars[0]

      // Keep currently selected nft if list is being updated
      // Fall back to first in other cases
      if (state.currentAvatar) {
        const updated = avatars.find(avatar => avatar.keyId === state.currentAvatar.keyId)
        state.currentAvatar = updated ?? first
      } else {
        state.currentAvatar = first
      }
    })
  }

  const getDownloadUrl = async (avatar: AvatarInfo) => {
    if (!avatar.nftInfo) {
      console.error(
        'NftsService.getDownloadUrl() called for avatar, but it has no NftInfo. This is unexpected behavior.'
      )
      return
    }

    widgetContext.update(state => {
      state.glbIsLoading = true
    })

    let url = `${config.cryptoServiceUrl}/nfts/contracts/${avatar.nftInfo.token_address}/${avatar.nftInfo.token_id}/downloadUrl`

    if (walletAddressParam && collectionParam === 'voids') {
      url += `?walletAddress=${walletAddressParam}`
    }

    const response = await fetch(url, {
      method: 'GET',
      headers
    })

    if (response.status === 404) {
      widgetContext.update(state => {
        state.error = {
          title: 'Missing Asset',
          messageHTML: {
            __html: `Oh no! Looks like ${avatar.displayName} might be missing! Reach out to us on
            <a href="https://discord.gg/jYCHaMASz7" target="_blank" alt="hallway discord">discord</a> or at 
            <a href="support@joinhallway.com" target="_blank">support@joinhallway.com</a> and we can get you some help!`
          }
        }
        state.currentAvatar = state.defaultAvatars[0]
      })
    }

    const { downloadUrl } = await response.json()

    widgetContext.update(state => {
      // Find and update item in list
      state.avatars = state.avatars.map(a => {
        if (a.keyId === avatar.keyId) {
          a.glbUrl = downloadUrl
        }
        return a
      })

      // Update 'current' info if this one is still current
      if (state.currentAvatar?.keyId === avatar.keyId) {
        state.currentAvatar.glbUrl = downloadUrl
      }
    })
  }

  return {
    fetchNftsList,
    getDownloadUrl
  }
}
