import { useEffect, useState } from 'react'

import { Spinner } from 'components/loading/spinner'
import { ReactComponent as WarningSvg } from 'assets/icons/warning.svg'

import { browserPermissionsInstructionsUrls, getBrowserType, PermissionState } from 'utils/browser'

import styles from './sceneLoadingOverlay.module.scss'

const messageQueue = ['Waiting for camera...', 'Downloading models...', 'Compiling...', 'Hooking up your avatar...']

const browserPermissionsInstructionsUrl = browserPermissionsInstructionsUrls[getBrowserType()]

type Props = {
  videoInPermissionState: PermissionState
}

type PermissionError = {
  title: string
  description: string
}

export function SceneLoadingOverlay({ videoInPermissionState }: Props) {
  const [messageId, setMessageId] = useState(0)

  useEffect(() => {
    // Recursive setTimeout avoids pileup of callbacks from setInterval
    let timeout: number
    const nextMessage = () => setMessageId(id => Math.min(id + 1, messageQueue.length - 1))
    const loop = () => {
      nextMessage()
      timeout = window.setTimeout(() => {
        loop()
      }, 1000)
    }
    videoInPermissionState === 'granted' && loop()
    return () => clearTimeout(timeout)
  }, [videoInPermissionState])

  const isError = (permissionState: PermissionState) => {
    switch (permissionState) {
      case 'invalidAPIToken':
      case 'noAPIToken':
      case 'videoDenied':
        return true
      default:
        return false
    }
  }

  const handlePermissionError = (permissionState: PermissionState) => {
    let title: string
    let description: string

    switch (permissionState) {
      case 'noAPIToken':
        title = 'No API token'
        description = 'API token error: You must provide an API token'
        break
      case 'invalidAPIToken':
        title = 'Auth Error'
        description = 'API token error: invalid'
        break
      case 'videoDenied':
        title = 'Camera permission denied'
        description = 'Camera error: Please reset permissions for this page and reload'
        break
    }

    return <PermissionErrorOverlay title={title} description={description} />
  }

  return (
    <div className={styles.loaderContainer} style={{ position: 'relative' }}>
      {isError(videoInPermissionState) ? (
        <>
          {handlePermissionError(videoInPermissionState)}
          {videoInPermissionState === 'videoDenied' && browserPermissionsInstructionsUrl && (
            <p className={styles.loaderSubText}>
              <a href={browserPermissionsInstructionsUrl} target="_blank">
                Click here
              </a>{' '}
              for instructions for your browser.
            </p>
          )}
        </>
      ) : (
        <>
          <Spinner size={80} thickness={3} />
          <div className={styles.loaderText}>{messageQueue[messageId]}</div>
        </>
      )}
    </div>
  )
}

const PermissionErrorOverlay = ({ title, description }: PermissionError) => {
  return (
    <>
      <WarningSvg />
      <p className={styles.loaderText}>{title}</p>
      <p className={styles.loaderSubText}>{description}</p>
    </>
  )
}
