import { ModelType } from '@quarkworks-inc/avatar-webkit-rendering'

import { NftCollection } from 'service/useNftCollections'
import { valueForManyKeys } from 'utils/json'

import { NftInfo } from './NftInfo'

export type AvatarInfo = {
  keyId: string
  displayName: string
  displayNameShort: string
  modelType: ModelType
  // Some avatars will have this built into their static def, others will have
  // this set later after asking the API for a temporary link (ex. our NFT
  // collections).
  thumbnailUrl?: string
  glbUrl?: string
  backgroundUrl?: string
  // Note: Not all avatars are Nfts. Some are 'default' characters for a
  // collection. Others are built-in Hallway characters.
  nftInfo?: NftInfo
}

export const hallwayDefaultAvatars: AvatarInfo[] = [
  {
    keyId: 'hallway__emoji',
    displayName: 'Mr. Emoji',
    modelType: 'emoji',
    displayNameShort: 'Mr. Emoji',
    glbUrl: 'https://hallway-public.nyc3.cdn.digitaloceanspaces.com/models/Smiley_eye.glb',
    thumbnailUrl: 'https://hallway-public.nyc3.cdn.digitaloceanspaces.com/thumbnails/emoji.webp'
  },
  // {
  //   keyId: 'hallway__void',
  //   modelType: 'void',
  //   displayName: 'VOID #3157',
  //   displayNameShort: 'VOID #3157',
  //   glbUrl: 'https://hallway-private.nyc3.cdn.digitaloceanspaces.com/avatars/_defaults/voids/void_3157.glb',
  //   thumbnailUrl: 'https://hallway-public.nyc3.cdn.digitaloceanspaces.com/thumbnails/void.webp'
  // },
  // {
  //   keyId: 'hallway__chib',
  //   modelType: 'chib',
  //   displayName: 'Chib #1',
  //   displayNameShort: 'Chib #1',
  //   glbUrl: 'https://hallway-public.nyc3.cdn.digitaloceanspaces.com/models/1.glb',
  //   thumbnailUrl: 'https://hallway-public.nyc3.cdn.digitaloceanspaces.com/thumbnails/1.webp'
  // },
  // {
  //   keyId: 'hallway_alienBoy',
  //   modelType: 'alienBoy',
  //   displayName: 'Alien Boy',
  //   displayNameShort: 'AB #225',
  //   glbUrl: 'https://hallway-public.nyc3.digitaloceanspaces.com/models/alien_boy_225.glb',
  //   thumbnailUrl: 'https://hallway-public.nyc3.cdn.digitaloceanspaces.com/thumbnails/alienBoy.webp'
  // },
  // {
  //   keyId: 'hallway__rpm',
  //   modelType: 'readyPlayerMe',
  //   displayName: 'Ready Player Me',
  //   displayNameShort: 'RPM - Rose',
  //   glbUrl: 'https://hallway-public.nyc3.cdn.digitaloceanspaces.com/models/rose.glb',
  //   thumbnailUrl: 'https://hallway-public.nyc3.cdn.digitaloceanspaces.com/thumbnails/rose.webp'
  // },
  // {
  //   keyId: 'hallway__mozilla',
  //   modelType: 'mozilla',
  //   displayName: 'Mozilla',
  //   displayNameShort: 'Mozilla Hubs',
  //   glbUrl: 'https://hallway-public.nyc3.cdn.digitaloceanspaces.com/models/mozilla.glb',
  //   thumbnailUrl: 'https://hallway-public.nyc3.cdn.digitaloceanspaces.com/thumbnails/mozilla.webp'
  // },
  {
    keyId: 'hallway_zuck',
    modelType: 'zuck',
    displayName: 'ZUCK',
    displayNameShort: 'ZUCK',
    glbUrl: 'https://hallway-public.nyc3.cdn.digitaloceanspaces.com/models/zuck_compressed.glb',
    thumbnailUrl: 'https://hallway-public.nyc3.cdn.digitaloceanspaces.com/thumbnails/zuck.webp',
    backgroundUrl: 'https://hallway-public.nyc3.cdn.digitaloceanspaces.com/backgrounds/paris-illustration.webp'
  }
]

export const avatarInfoForNft = (nft: NftInfo, collection?: NftCollection): AvatarInfo => {
  let displayName: string
  let displayNameShort: string

  if (collection) {
    displayName = `${collection.nameSingular} #${nft.token_id}`
    displayNameShort = `#${nft.token_id}`
  } else {
    displayName = nft.metaObj?.name ?? nft.metaObj?.title ?? `${nft.symbol} #${nft.token_id}`
    displayNameShort = displayName
  }

  // there are no standards XD
  const thumbnailUrl = valueForManyKeys(nft.metaObj, [
    'thumbnail',
    'thumbnailUrl',
    'thumbnail_url',
    'image',
    'imageUrl',
    'image_url'
  ])

  let glbUrl = valueForManyKeys(nft.metaObj, ['glb', 'glbUrl', 'glb_url', 'animationUrl', 'animation_url'])

  if (collection.modelType === 'chib') {
    // These collections have a glb url that we dont want to use
    glbUrl = undefined
  }

  return {
    keyId: nft.keyId,
    displayName,
    displayNameShort,
    thumbnailUrl,
    glbUrl,
    modelType: collection.modelType,
    nftInfo: nft
  }
}
