import cx from 'classnames'

import styles from './spinner.module.scss'

type Props = {
  size?: number
  thickness?: number
} & React.ComponentProps<'span'>

export function Spinner({ className, size = 20, thickness = 3, ...props }: Props) {
  return (
    <span
      style={{ width: size, height: size, borderWidth: thickness }}
      className={cx([styles.spinner, className])}
      {...props}
    />
  )
}
