import { AUPredictor } from '@quarkworks-inc/avatar-webkit'
import { AvatarRenderer, AvatarWorld, modelFactory, ModelType } from '@quarkworks-inc/avatar-webkit-rendering'
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useMoralis } from 'react-moralis'
import cx from 'classnames'

import { createApi } from 'unsplash-js'

import { AccountView } from 'components/account'
import { BackgroundColorOptions } from 'avatar/BackgroundColorOptions'
import { BackgroundOptions } from 'avatar/BackgroundOptions'
import { BackgroundsPage } from 'components/sideBar/backgrounds/backgrounds'
import { Button } from 'components/shared/button'
import { ConnectWalletView } from 'components/emptyState/connectWallet'
import { ErrorView } from 'components/emptyState/error'
import { getMediaRecorderMimeType, PermissionState } from 'utils/browser'
import { HeaderView } from 'components/header'
import { IconButton } from 'components/shared/iconButton'
import { InventoryPage } from 'components/sideBar/inventory/inventory'
import { MenuSelect } from 'components/shared/menuSelect'
import { RecordPage } from 'components/sideBar/record/record'
import { SceneLoadingOverlay } from 'components/loading/sceneLoadingOverlay'
import { SideBar } from 'components/sideBar/sideBar'
import { StreamingPage } from 'components/sideBar/streaming/streaming'
import { useNftsService } from 'service/useNftsService'
import { useOSC } from 'service/osc/useOSC'
import { WidgetContext } from 'state/WidgetContext'

import { ReactComponent as CameraSvg } from './assets/icons/camera.svg'
import { ReactComponent as CloseSvg } from './assets/icons/close.svg'
import { ReactComponent as LandscapeSvg } from './assets/icons/landscape.svg'
import { ReactComponent as FaceSvg } from './assets/icons/face.svg'
import { ReactComponent as StreamSvg } from './assets/icons/stream.svg'

import styles from './widget.module.scss'

const CAMERA_WIDTH = 640
const CAMERA_HEIGHT = 360

type AvatarState = 'loading' | 'running' | 'paused' | 'error'
type Tab = 'avatar' | 'background' | 'record' | 'stream' | null

export const AvatarWidget = () => {
  const context = useContext(WidgetContext)
  const { isAuthenticated, isInitialized } = useMoralis()
  const { getDownloadUrl, fetchNftsList } = useNftsService()
  const { sendPrediction } = useOSC()

  const [avatarState, setAvatarState] = useState<AvatarState>('paused')
  const [videoInDevices, setVideoInDevices] = useState<MediaDeviceInfo[]>([])
  const [videoInPermissionState, setVideoInPermissionState] = useState<PermissionState>('unknown')
  const [selectedVideoInDeviceId, setSelectedVideoInDeviceId] = useState<string | null>(null)
  const [[sceneWidth, sceneHeight], setSceneSize] = useState<[number, number]>([0, 0])
  const [background, setBackground] = useState<BackgroundOptions | BackgroundColorOptions>(BackgroundColorOptions.pink)
  const [tab, setTab] = useState<Tab>('avatar')

  const mainDivRef = useRef<HTMLDivElement>()
  const sceneCanvasRef = useRef<HTMLCanvasElement>()
  const avatarRenderer = useRef<AvatarRenderer>()
  const avatarWorld = useRef<AvatarWorld>()
  const mainContainerRef = useRef<HTMLDivElement>()

  const predictor = useMemo(
    () =>
      new AUPredictor({
        apiToken: context.params.apiToken,
        shouldMirrorOutput: true
      }),
    []
  )

  const updateBackground = () => {
    if (!avatarWorld.current) return

    if (context.currentAvatar.backgroundUrl) {
      avatarRenderer.current.environmentLoader.load(context.currentAvatar.backgroundUrl).then(texture => {
        avatarWorld.current.setBackground(texture)
      })
    } else {
      if (background instanceof BackgroundOptions) {
        const texture = avatarRenderer.current.environmentLoader.load(background.url)
        avatarWorld.current.setBackground(texture)
        avatarWorld.current.setEnvironment(texture)
      } else if (background instanceof BackgroundColorOptions) {
        avatarWorld.current.setBackground(background.hex)
      }
    }
  }

  useEffect(() => {
    async function authenticate() {
      try {
        await predictor.authenticate()
      } catch (e) {
        setVideoInPermissionState(context.params.apiToken ? 'invalidAPIToken' : 'noAPIToken')
        setAvatarState('error')
        return
      }
    }
    authenticate()
  }, [])

  const calculateSceneSize = useCallback(() => {
    const rect = mainDivRef.current?.getBoundingClientRect()
    if (rect) {
      setSceneSize([rect.width, rect.height])
      avatarWorld.current?.resize()
    }
  }, [])

  // window resizing
  // needs to be first so the scene knows it's initial size
  useEffect(() => {
    calculateSceneSize()
    window.addEventListener('resize', calculateSceneSize)

    return () => window.removeEventListener('resize', calculateSceneSize)
  }, [])

  useEffect(() => {
    const mouseDidMove = (event: MouseEvent) => {
      if (avatarState !== 'running') {
        avatarWorld.current?.lookAt(
          event.clientX / document.body.clientWidth - 0.5,
          -event.clientY / document.body.clientHeight + 0.5,
          1
        )
      }
    }

    window.addEventListener('mousemove', mouseDidMove)

    return () => window.removeEventListener('mousemove', mouseDidMove)
  }, [avatarState])

  useEffect(() => {
    const doubleClickListener = () => {
      const fullscreenElement = document.fullscreenElement
      const canvas = sceneCanvasRef.current
      if (!canvas) return

      if (!fullscreenElement) {
        if (canvas.requestFullscreen) {
          canvas.requestFullscreen()
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        }
      }
    }

    sceneCanvasRef.current.addEventListener('dblclick', doubleClickListener)

    return () => sceneCanvasRef.current.removeEventListener('dblclick', doubleClickListener)
  }, [sceneCanvasRef])

  // initialize scene
  // need to have this be one of the first effects so the renderer/world refs can be set
  useEffect(() => {
    const initScene = async () => {
      if (avatarRenderer.current || !sceneWidth || !sceneHeight) return

      const canvas = sceneCanvasRef.current
      const renderer = new AvatarRenderer({ canvas })
      const world = new AvatarWorld({
        container: canvas,
        renderer,
        useDefaultBackground: false
      })

      avatarRenderer.current = renderer
      avatarWorld.current = world

      if (background instanceof BackgroundOptions) {
        const texture = await avatarRenderer.current.environmentLoader.load(background.url)
        world.setBackground(texture)
        world.setEnvironment(texture)
      } else if (background instanceof BackgroundColorOptions) {
        world.setBackground(background.hex)
      }

      renderer.updatables.push(world)
      renderer.renderables.push(world)

      renderer.start()
    }

    initScene()
  }, [sceneWidth, sceneHeight])

  // Fetch NFT list
  useEffect(() => {
    if (isInitialized) {
      fetchNftsList()
    }
  }, [isAuthenticated, isInitialized, context.testingAddress, context.params.walletAddress, context.params.collection])

  // update scene with blend shape data
  useEffect(() => {
    const subscription = predictor.dataStream.subscribe(results => {
      if (avatarState === 'loading') setAvatarState('running')

      results.transform.z = 0 // Z transform is broken for mobile video

      sendPrediction(results)
      avatarWorld.current?.updateFromResults(results)
    })

    return () => subscription.unsubscribe()
  }, [avatarState])

  // Background changed
  useEffect(() => {
    const setBackground = async () => {
      if (!avatarRenderer.current || !avatarWorld.current) return
      if (background instanceof BackgroundOptions) {
        const texture = await avatarRenderer.current.environmentLoader.load(background.url)
        avatarWorld.current.setBackground(texture)

        if (background.url.includes('unsplash')) {
          // Unsplash guidelines require we send them download analytics
          unsplashApi.photos.trackDownload({ downloadLocation: background.downloadLocation })
        } else {
          // If it's not an unsplash image, it can be used as a lighting environment
          // TODO: probably not the safest assumption
          avatarWorld.current.setEnvironment(texture)
        }
      } else if (background instanceof BackgroundColorOptions) {
        avatarWorld.current.setBackground(background.hex)
      }
    }

    setBackground()
  }, [background])

  // Avatar changed
  useEffect(() => {
    const { currentAvatar } = context

    console.log('avatar info changed')
    if (!currentAvatar) {
      // TODO: remove model from scene when null
      console.log('TODO: remove model from scene when null')
      return
    }

    if (!currentAvatar.glbUrl) {
      // This will update the AvatarInfo with a glbUrl and this effect will be called again
      getDownloadUrl(context.currentAvatar)
      return
    }

    const type: ModelType =
      context.nftCollection?.modelType ?? context.params.avatarType ?? context.currentAvatar.modelType

    console.log('fetching model...')

    updateBackground()

    modelFactory(type, context.currentAvatar.glbUrl).then(model => {
      console.log('adding model to world...')
      avatarWorld.current?.setModel(model)

      context.update(state => {
        state.glbIsLoading = false
      })
    })
  }, [context.currentAvatar])

  const fetchVideoInDevices = async () => {
    const mediaDevices = await navigator.mediaDevices.enumerateDevices()
    const videoInDevices = mediaDevices.filter(device => device.kind === 'videoinput' && device.label !== '')
    setVideoInDevices(videoInDevices)
  }

  // initial fetch devices (only works if permissions already granted)
  useEffect(() => {
    fetchVideoInDevices()
    navigator.mediaDevices.addEventListener('devicechange', fetchVideoInDevices)

    return () => navigator.mediaDevices.removeEventListener('devicechange', fetchVideoInDevices)
  }, [])

  // devices changed
  useEffect(() => {
    // 1. If a deviceId is not selected,
    //    a) pull saved videoDeviceId from storage and use that
    //    b) else, use first device in list
    // 2. If a deviceId is selected
    //    a) use this device if it's in the list
    //    b) else, use first device in list
    let desiredVideoDeviceId = selectedVideoInDeviceId

    if (!desiredVideoDeviceId) {
      try {
        desiredVideoDeviceId = window.localStorage.getItem('videoDeviceId')
      } catch (e) {
        console.warn(e)
      }
    }

    const selectedVideoDevice =
      videoInDevices.length &&
      (videoInDevices.find(device => device.deviceId === desiredVideoDeviceId) ?? videoInDevices[0])

    setSelectedVideoInDeviceId(selectedVideoDevice?.deviceId)
  }, [videoInDevices])

  const startAvatar = useCallback(async () => {
    if (context.params.apiToken === 'undefined' || !context.params.apiToken) {
      setVideoInPermissionState('noAPIToken')
      return
    }

    // Cleanup previous stream, if it exists
    predictor.stop()
    predictor.stream?.getTracks().forEach(track => track.stop())

    let stream
    try {
      stream = await navigator.mediaDevices.getUserMedia({
        video: {
          width: CAMERA_WIDTH,
          height: CAMERA_HEIGHT,
          deviceId: selectedVideoInDeviceId ? { exact: selectedVideoInDeviceId } : undefined
        }
      })
      setVideoInPermissionState('granted')
    } catch (e) {
      console.log('Camera permissions denied')
      setVideoInPermissionState('videoDenied')
      return
    }

    await predictor.start({ stream })

    // Remember which device the browser initially picks (in Firefox, user gets this choice)
    if (!selectedVideoInDeviceId) {
      setSelectedVideoInDeviceId(stream.getVideoTracks()[0].getSettings().deviceId)
    }

    fetchVideoInDevices() // Update device list
  }, [selectedVideoInDeviceId])

  const stopAvatar = useCallback(() => predictor.stop({ stopStream: true }), [])

  // avatar state change
  useEffect(() => {
    if (avatarState === 'running') return

    // When state is changed to 'loading', start up the avatar
    // When the first frame is received, we will update the state to running
    avatarState === 'loading' ? startAvatar() : stopAvatar()
  }, [avatarState])

  // streaming state change
  useEffect(() => {
    // Auto-trigger scene start if the user turns on streaming
    if (avatarState === 'paused' && context.oscState === 'open') {
      setAvatarState('loading')
    }
  }, [context.oscState])

  // selected device changed
  useEffect(() => {
    if (selectedVideoInDeviceId) {
      try {
        window.localStorage.setItem('videoDeviceId', selectedVideoInDeviceId)
      } catch (e) {
        console.warn(e)
      }
    }

    if (avatarState === 'running') startAvatar()
  }, [selectedVideoInDeviceId])

  const goLiveClicked = () => {
    if (avatarState === 'loading') return
    setAvatarState(avatarState === 'paused' ? 'loading' : 'paused')
  }

  const videoInSelected = (deviceId: string) => {
    setSelectedVideoInDeviceId(deviceId)
  }

  // set tab to null on mobile
  useEffect(() => {
    if (mainContainerRef.current.offsetWidth < 768) {
      setTab(null)
    }
  }, [mainContainerRef.current])

  const unsplashApi = createApi({ apiUrl: 'https://proxy.joinhallway.com' })

  return (
    <div className={styles.app}>
      <div className={styles.container} ref={mainContainerRef}>
        <div className={styles.content}>
          <div className={styles.main}>
            <HeaderView />
            <div className={styles.sceneContainer} ref={mainDivRef}>
              <canvas ref={sceneCanvasRef} width={sceneWidth} height={sceneHeight} style={{ position: 'absolute' }} />
              {context.recordingDuration >= 0 && <div className={styles.recordIndicator} />}
              <div
                className={cx(
                  styles.loadingContainer,
                  avatarState !== 'loading' && avatarState !== 'error' && styles.loadingContainerHidden
                )}
              >
                <SceneLoadingOverlay videoInPermissionState={videoInPermissionState} />
              </div>
              {context.error && <ErrorView error={context.error} />}
            </div>
          </div>
          <div className={styles.sideBarContainer}>
            <SideBar visible={tab === 'avatar'} heading="Inventory">
              <InventoryPage />
            </SideBar>
            <SideBar visible={tab === 'background'} heading="Scene">
              <BackgroundsPage
                value={background}
                options={BackgroundColorOptions.all}
                onChange={setBackground}
                api={unsplashApi}
              />
            </SideBar>
            <SideBar visible={tab === 'record' && getMediaRecorderMimeType() !== null} heading="Record">
              <RecordPage canvasRef={sceneCanvasRef} />
            </SideBar>
            <SideBar visible={tab === 'stream'} heading="Stream">
              <StreamingPage />
            </SideBar>
          </div>
        </div>
        <div className={styles.controlBar}>
          <div className={styles.cameraButtonsContainer}>
            <div className={styles.goLiveButtonContainer}>
              <Button
                title={avatarState === 'running' ? 'Pause' : 'Try Me'}
                onClick={goLiveClicked}
                style="primary"
                size="large"
                disabled={avatarState === 'error'}
                className={styles.goLiveButton}
              />
            </div>
            <div
              className={cx([
                styles.deviceSelectContainer,
                videoInDevices.length === 0 && styles.deviceSelectContainerHidden
              ])}
            >
              <MenuSelect
                errorMessage="Unable to access video devices"
                placeholderOption="Select video device"
                label={videoInDevices.find(device => device.deviceId === selectedVideoInDeviceId)?.label ?? ''}
                options={videoInDevices.map(device => ({ value: device.deviceId, label: device.label }))}
                permission={true}
                disabled={videoInDevices.length === 0}
                value={videoInDevices.find(device => device.deviceId === selectedVideoInDeviceId)?.deviceId ?? ''}
                onChangeValue={videoInSelected}
              />
            </div>
          </div>

          <div className={styles.iconButtonsContainer}>
            <IconButton label="Avatar" active={tab === 'avatar'} onClick={() => setTab('avatar')}>
              <FaceSvg />
            </IconButton>
            <IconButton label="Scene" active={tab === 'background'} onClick={() => setTab('background')}>
              <LandscapeSvg />
            </IconButton>
            {getMediaRecorderMimeType() !== null && (
              <IconButton label="Record" active={tab === 'record'} onClick={() => setTab('record')}>
                <CameraSvg />
              </IconButton>
            )}
            <IconButton
              label="Stream"
              active={tab === 'stream'}
              onClick={() => setTab('stream')}
              className={styles.desktopOnly}
            >
              <StreamSvg />
            </IconButton>
            {isAuthenticated && (
              <div className={styles.iconWalletMobile}>
                <AccountView />
              </div>
            )}
          </div>

          <div className={styles.iconWalletContainer}>
            {!isAuthenticated && (
              <div key="connect-wallet-footer" className={styles.connectWallet}>
                <ConnectWalletView />
              </div>
            )}
            <AccountView />
          </div>

          {/* Mobile SideBar */}
          <div className={cx(styles.mobileSideBar, tab !== null ? styles.openMobileSideBar : null)}>
            <div className={styles.closeIconContainer} style={{ visibility: tab !== null ? 'visible' : 'hidden' }}>
              <CloseSvg className={styles.closeIcon} onClick={() => setTab(null)} />
            </div>
            <SideBar visible={tab === 'avatar'} heading="Inventory">
              <InventoryPage />
            </SideBar>
            <SideBar visible={tab === 'background'} heading="Scene">
              <BackgroundsPage
                value={background}
                options={BackgroundColorOptions.all}
                onChange={setBackground}
                api={unsplashApi}
              />
            </SideBar>
            <SideBar visible={tab === 'record'} heading="Record">
              <RecordPage canvasRef={sceneCanvasRef} />
            </SideBar>
            <SideBar visible={tab === 'stream'} heading="Stream">
              <StreamingPage />
            </SideBar>
          </div>
        </div>
      </div>
    </div>
  )
}
