export type GetDownloadUrlBody = {
  contractAddress: string
  tokenId: string
  downloadUrl: string
}

export enum UserRole {
  admin = 'admin',
  none = 'none'
}

export type WidgetError = {
  title: string
  messageHTML: {
    __html: string
  }
}
