import { useContext } from 'react'
import cx from 'classnames'

import { AvatarLoadingOverlay } from 'components/loading/avatarLoadingOverlay'
import { AvatarInfo } from 'state/AvatarInfo'
import { WidgetContext } from 'state/WidgetContext'

import fallbackImageUrl from 'assets/thumbnails/fallback-void.svg'

import sideBarStyles from '../sideBar.module.scss'

import styles from './avatarItem.module.scss'

type Props = {
  avatar: AvatarInfo
  onClick?: (avatar: AvatarInfo) => void
}

export const AvatarItem = ({ avatar, onClick }: Props) => {
  const widgetContext = useContext(WidgetContext)

  const isSelected = avatar.keyId === widgetContext.currentAvatar?.keyId
  const isLoading = isSelected && widgetContext.glbIsLoading

  return (
    <div className={cx(sideBarStyles.item, isSelected && sideBarStyles.active)}>
      <button className={cx(styles.thumbnail, isSelected && styles.active)} onClick={() => onClick(avatar)}>
        <img
          src={avatar.thumbnailUrl ?? fallbackImageUrl}
          alt={avatar.displayName}
          className={styles.thumbnail}
          width="100%"
          height="100%"
        />
        {isLoading && <AvatarLoadingOverlay />}
      </button>
      <span className={sideBarStyles.label}>{avatar.displayNameShort}</span>
    </div>
  )
}
