import { useRef, useState } from 'react'

import { Button } from 'components/shared/button'
import { useOSC } from 'service/osc/useOSC'

import styles from './streaming.module.scss'

const defaultHost = '127.0.0.1'
const defaultPort = 9000

export const StreamingPage = () => {
  const { state, open, close } = useOSC()

  const oscFormRef = useRef<HTMLFormElement>()
  const [hostValue, setHostValue] = useState('')
  const [portValue, setPortValue] = useState('')

  const onStartClicked = () => {
    if (!oscFormRef.current?.reportValidity()) return

    const host = hostValue.trim() || defaultHost
    const port = portValue.trim() || defaultPort

    open(host, port)
  }

  const onStopClicked = () => {
    close()
  }

  return (
    <div className={styles.page}>
      <form className={styles.section} ref={oscFormRef} onSubmit={e => e.preventDefault()}>
        <h4>OSC Streaming</h4>
        <div className={styles.inputGroup}>
          <label>Host</label>
          <input
            type="text"
            placeholder="127.0.0.1"
            value={hostValue}
            onChange={e => setHostValue(e.target.value)}
          ></input>
        </div>
        <div className={styles.inputGroup}>
          <label>Port</label>
          <input
            type="number"
            placeholder="9000"
            value={portValue}
            onChange={e => setPortValue(e.target.value)}
          ></input>
        </div>
        <Button
          title={state === 'closed' ? 'Start' : 'Stop'}
          style="secondary"
          size="medium"
          onClick={state === 'closed' ? onStartClicked : onStopClicked}
        />
      </form>
    </div>
  )
}
