import { useContext } from 'react'

import { ReactComponent as WalletEmptySvg } from 'assets/icons/wallet-empty.svg'
import { Button } from 'components/shared/button'
import { WidgetContext } from 'state/WidgetContext'
import { useNftsService } from 'service/useNftsService'

import styles from './walletEmpty.module.scss'

export const WalletEmptyView = () => {
  const { nftCollection } = useContext(WidgetContext)
  const { fetchNftsList } = useNftsService()

  let message = "Uh oh! Looks like you don't have any NFTs in your wallet"
  let marketplaceUrl = 'https://opensea.io'

  if (nftCollection) {
    message = `Uh oh! Looks like you don't own any ${nftCollection.namePlural}`
    marketplaceUrl = nftCollection.marketplaceUrl
  }

  return (
    <div className={styles.container}>
      <div className={styles.image}>
        <WalletEmptySvg width="100%" height="100%" />
      </div>
      <p className={styles.message}>{message}</p>
      <div>
        <a href={marketplaceUrl} target="_blank" rel="noopener noreferrer">
          <Button title="Marketplace" style="secondary" size="medium" />
        </a>
      </div>
      <p className={styles.refresh} onClick={fetchNftsList}>
        Refresh
      </p>
    </div>
  )
}
