import { AvatarPrediction } from '@quarkworks-inc/avatar-webkit'
import OSC from 'osc-js'
import { useContext } from 'react'

import { WidgetContext } from 'state/WidgetContext'

import { FaceItKeys } from './faceItKeys'

export type OSCState = 'closed' | 'opening' | 'open'

/**
 * Want to maintain a single global connection
 */
let osc: OSC | null = null

export const useOSC = () => {
  const context = useContext(WidgetContext)

  const oscStatus = (): number => osc?.status() ?? OSC.STATUS.IS_NOT_INITIALIZED

  const open = (host: string, port: string | number) => {
    if (osc) {
      console.warn(`open() called, but OSC connection already exists. status: ${osc.status}`)
      return
    }

    if (typeof port !== 'number') {
      port = parseInt(port)
    }

    if (isNaN(port)) {
      throw new Error(`Port value is not a number. port: ${port}`)
    }

    console.log(`Opening OSC connection to ${host}:${port}`)
    context.update(draft => ({ ...draft, oscState: 'opening' }))

    osc = new OSC({ plugin: new OSC.WebsocketClientPlugin({ host, port }) })
    osc.on('open', () => {
      context.update(draft => ({ ...draft, oscState: 'open' }))
      console.log('OSC connection opened.')
    })
    osc.open()
  }

  const close = () => {
    if (!osc) {
      console.warn('close() called, but OSC connection does not exist.')
      return
    }

    osc.close()
    osc = null
    context.update(draft => ({ ...draft, oscState: 'closed' }))
  }

  const sendPrediction = (prediction: AvatarPrediction) => {
    if (!osc || osc.status() !== OSC.STATUS.IS_OPEN) return

    const messages: OSC.Message[] = FaceItKeys.sorted.flatMap((key, idx) => {
      const val = prediction.blendShapes[key]
      const rounded = parseFloat(val.toFixed(3))
      return val ? [new OSC.Message('/W', idx, rounded)] : []
    })

    messages.forEach(m => osc.send(m))
  }

  return {
    state: context.oscState,
    oscStatus,
    open,
    close,
    sendPrediction
  }
}
