import cx from 'classnames'
import { MouseEvent } from 'react'

import styles from './iconButton.module.scss'

type Props = {
  children: JSX.Element
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
  label?: string
  active?: boolean
  className?: string
}

export function IconButton({ children, onClick, label = '', active = false, className }: Props) {
  return (
    <button className={cx(styles.iconButton, active && styles.iconButtonActive, className)} onClick={onClick}>
      <div className={styles.iconContainer}>{children}</div>
      <label className={styles.iconLabel}>{label}</label>
    </button>
  )
}
