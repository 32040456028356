// Source: https://github.com/w-okada/image-analyze-workers/blob/728898121e5062fdfc639c99e8cf2d1e1935fd2e/002_facemesh-worker-js/src/BrowserUtil.ts

export enum BrowserType {
  'MSIE',
  'EDGE',
  'CHROME',
  'SAFARI',
  'FIREFOX',
  'OPERA',
  'BRAVE',
  'OTHER'
}

export type PermissionState = 'unknown' | 'granted' | 'videoDenied' | 'noAPIToken' | 'invalidAPIToken'

export const getBrowserType = () => {
  const userAgent = window.navigator.userAgent.toLowerCase()

  // @ts-expect-error non-supported
  if (navigator?.brave) {
    return BrowserType.BRAVE
  } else if (userAgent.indexOf('msie') !== -1 || userAgent.indexOf('trident') !== -1) {
    return BrowserType.MSIE
  } else if (userAgent.indexOf('opera') !== -1 || userAgent.indexOf('opr') !== -1) {
    return BrowserType.OPERA
  } else if (userAgent.indexOf('edge') !== -1) {
    return BrowserType.EDGE
  } else if (userAgent.indexOf('chrome') !== -1) {
    return BrowserType.CHROME
  } else if (userAgent.indexOf('safari') !== -1) {
    return BrowserType.SAFARI
  } else if (userAgent.indexOf('firefox') !== -1) {
    return BrowserType.FIREFOX
  } else {
    return BrowserType.OTHER
  }
}

export const avatarsAvailable = () => {
  const types = [BrowserType.CHROME, BrowserType.OPERA, BrowserType.BRAVE, BrowserType.FIREFOX, BrowserType.SAFARI]

  return types.includes(getBrowserType())
}

export const gestureDetectionDefault = () => {
  const types = [BrowserType.CHROME, BrowserType.OPERA, BrowserType.BRAVE, BrowserType.FIREFOX, BrowserType.SAFARI]

  return types.includes(getBrowserType())
}

export const getMediaRecorderMimeType = () => {
  const preferredMimeTypes = ['video/webm;codecs=h264', 'video/webm;codecs=vp9', 'video/webm;codecs=vp8,opus']

  for (const mimeType of preferredMimeTypes) {
    if (MediaRecorder.isTypeSupported(mimeType)) {
      return mimeType
    }
  }

  console.error('Browser does not support preferred MediaRecorder types')
  return null
}

export const browserPermissionsInstructionsUrls: { [browser in BrowserType]?: string } = {
  [BrowserType.CHROME]: 'https://support.google.com/chrome/answer/114662?hl=en&co=GENIE.Platform%3DDesktop',
  [BrowserType.FIREFOX]: 'https://support.mozilla.org/en-US/kb/site-permissions-panel',
  [BrowserType.BRAVE]: 'https://support.brave.com/hc/en-us/articles/360018205431-How-do-I-change-site-permissions-',
  [BrowserType.SAFARI]: 'https://support.apple.com/guide/safari/websites-ibrwe2159f50/mac',
  [BrowserType.EDGE]:
    'https://support.microsoft.com/en-us/windows/windows-camera-microphone-and-privacy-a83257bc-e990-d54a-d212-b5e41beba857',
  [BrowserType.OPERA]: 'https://help.opera.com/en/latest/web-preferences/'
}
