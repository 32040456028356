import cx from 'classnames'

import { BackgroundOptions } from 'avatar/BackgroundOptions'
import { BackgroundColorOptions } from 'avatar/BackgroundColorOptions'

import sideBarStyles from '../sideBar.module.scss'

import styles from './backgroundItem.module.scss'

type Props = {
  idx: number
  background: BackgroundOptions | BackgroundColorOptions
  isSelected: boolean
  onClick: (background: BackgroundOptions | BackgroundColorOptions) => void
}

export const BackgroundItem = ({ background, isSelected, onClick }: Props) => {
  if (background instanceof BackgroundOptions) {
    const backgroundOptions = background as BackgroundOptions
    let attribution = <div></div>
    if (backgroundOptions.attrib) {
      // prettier-ignore
      attribution = (
        <div className={styles.attrib}>
          <p>By{'\u00A0'}</p><a href={backgroundOptions.attribLink + '?utm_source=hallway_tile_widget&utm_medium=referral'}>{backgroundOptions.attrib}</a>
          <p>{'\u00A0'}On{'\u00A0'}</p><a href="https://unsplash.com">Unsplash</a>
        </div>
      )
    }
    return (
      <div className={sideBarStyles.item}>
        <button className={cx(styles.thumbnail, isSelected && styles.active)} onClick={() => onClick(background)}>
          <img
            src={backgroundOptions.thumbnailUrl}
            alt={backgroundOptions.name}
            crossOrigin="anonymous"
            width="100%"
            height="100%"
          />
        </button>
        {attribution}
      </div>
    )
  } else if (background instanceof BackgroundColorOptions) {
    const backgroundColor = background as BackgroundColorOptions

    return (
      <div className={sideBarStyles.item}>
        <div
          className={cx(sideBarStyles.color, isSelected && sideBarStyles.colorActive)}
          onClick={() => onClick(background)}
          style={{ backgroundColor: backgroundColor.hex }}
        ></div>
      </div>
    )
  }
}
