import { useContext, useEffect, useRef, useState } from 'react'

import { Button } from 'components/shared/button'
import { MenuSelect } from 'components/shared/menuSelect'
import { WidgetContext } from 'state/WidgetContext'
import { getMediaRecorderMimeType } from 'utils/browser'

import styles from './record.module.scss'

type Props = {
  canvasRef: React.RefObject<HTMLCanvasElement>
}

export function RecordPage({ canvasRef }: Props) {
  const context = useContext(WidgetContext)

  const intervalIdRef = useRef<number | null>(null)
  const mediaRecorderRef = useRef<MediaRecorder | null>(null)
  const mediaStreamRef = useRef<MediaStream | null>(null)

  const [audioInDevices, setAudioInDevices] = useState<MediaDeviceInfo[]>([])
  const [selectedAudioInDeviceId, setSelectedAudioInDeviceId] = useState<string | null>(null)
  const [audioInEnabled, setAudioInEnabled] = useState(false)
  const [displayDowloadForm, setDisplayDownloadForm] = useState(false)
  const [blobUrl, setBlobUrl] = useState('')

  const recordingActive = context.recordingDuration >= 0

  const fetchAudioInDevices = async () => {
    const audioInDevices = (await navigator.mediaDevices.enumerateDevices()).filter(
      device => device.kind === 'audioinput' && device.label !== ''
    )
    setAudioInDevices(audioInDevices)
  }

  const initAudioInDevices = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true })

    // Remember which device the browser initially picks (in Firefox, user gets this choice)
    const deviceId = stream.getAudioTracks()[0].getSettings().deviceId
    setSelectedAudioInDeviceId(deviceId)

    fetchAudioInDevices()
  }

  // initial fetch devices (only works if permissions already granted)
  useEffect(() => {
    fetchAudioInDevices()

    navigator.mediaDevices.addEventListener('devicechange', fetchAudioInDevices)

    return () => navigator.mediaDevices.removeEventListener('devicechange', fetchAudioInDevices)
  }, [])

  useEffect(() => {
    let desiredAudioDeviceId = selectedAudioInDeviceId

    if (!desiredAudioDeviceId) {
      try {
        desiredAudioDeviceId = window.localStorage.getItem('audioDeviceId')
      } catch (e) {
        console.warn(e)
      }
    }

    const selectedAudioDevice =
      audioInDevices.length &&
      (audioInDevices.find(device => device.deviceId === desiredAudioDeviceId) ?? audioInDevices[0])

    setSelectedAudioInDeviceId(selectedAudioDevice?.deviceId)
  }, [audioInDevices])

  const startRecording = async () => {
    const mediaStream = canvasRef.current.captureStream()

    if (audioInEnabled) {
      const audioStream = await navigator.mediaDevices.getUserMedia({
        audio: {
          deviceId: selectedAudioInDeviceId ? { exact: selectedAudioInDeviceId } : undefined
        }
      })
      const audioTracks = audioStream.getAudioTracks() ?? []
      audioTracks.forEach(track => mediaStream.addTrack(track))
    }

    const mimeType = getMediaRecorderMimeType()
    console.log('Recording with mimeType', mimeType)

    const mediaRecorder = new MediaRecorder(mediaStream, { mimeType })

    mediaRecorder.ondataavailable = event => {
      if (event.data.size > 0) {
        const recordedChunks = [event.data]
        const blob = new Blob(recordedChunks, {
          type: 'video/webm'
        })
        createBlobLink(blob)
        setDisplayDownloadForm(true)
      } else {
        // ...
      }
    }

    mediaRecorder.start()

    const intervalId = window.setInterval(() => {
      context.update(state => {
        state.recordingDuration++
      })
    }, 1000)

    context.update(state => void (state.recordingDuration = 0))

    mediaStreamRef.current = mediaStream
    mediaRecorderRef.current = mediaRecorder
    intervalIdRef.current = intervalId
  }

  const stopRecording = () => {
    mediaRecorderRef.current.stop()
    mediaStreamRef.current.getTracks().forEach(track => track.stop())
    clearInterval(intervalIdRef.current)

    context.update(state => void (state.recordingDuration = -1))
    mediaRecorderRef.current = null
    intervalIdRef.current = null
  }

  const recordClicked = () => {
    if (context.recordingDuration >= 0) {
      stopRecording()
    } else {
      startRecording()
    }
  }

  const createBlobLink = (blob: Blob) => {
    const recordingSectionElement = document.getElementById('recording-section')
    const url = window.URL.createObjectURL(blob)
    setBlobUrl(url)

    const link = document.createElement('a')
    link.id = 'download-link'
    link.href = url
    link.target = '_blank'
    link.rel = 'noreferrer'
    recordingSectionElement.appendChild(link)
  }

  const recordNewVideo = () => {
    const link = document.getElementById('download-link')
    link.remove()
    window.URL.revokeObjectURL(blobUrl)
    setBlobUrl('')
    setDisplayDownloadForm(false)
  }

  const openBlobLink = () => {
    const link = document.getElementById('download-link')
    link.click()
  }

  const downloadRecordingForm = () => {
    return (
      <>
        <Button title="Download" style="primary" size="medium" onClick={openBlobLink} />
        <Button title="Record Video" style="secondary" size="medium" onClick={recordNewVideo} />
      </>
    )
  }

  const recordVideoForm = () => {
    return (
      <>
        <div className={styles.section}>
          <span>
            {recordingActive ? `Recording: ${formatDuration(context.recordingDuration)}` : 'Recording stopped'}
          </span>
          <Button title={recordingActive ? 'Stop' : 'Start'} style="secondary" size="medium" onClick={recordClicked} />
        </div>

        <div className={styles.section}>
          <label>
            <input
              disabled={recordingActive}
              type="checkbox"
              checked={audioInEnabled}
              onClick={initAudioInDevices}
              onChange={e => setAudioInEnabled(e.target.checked)}
            />
            Record Microphone
          </label>
          <div className={styles.deviceSelectContainer}>
            <MenuSelect
              value={selectedAudioInDeviceId ?? ''}
              placeholderOption="Select audio device"
              disabled={!audioInEnabled || recordingActive}
              label={audioInDevices.find(device => device.deviceId === selectedAudioInDeviceId)?.label}
              options={audioInDevices.map(device => ({ value: device.deviceId, label: device.label }))}
              onChangeValue={value => setSelectedAudioInDeviceId(value)}
            />
          </div>
          {/* @ts-expect-error non-supported */}
          {navigator?.brave && (
            <span className={styles.warning}>
              Please{' '}
              <a href="https://brave.com/shields/" target="_blank" rel="noopener noreferrer">
                disable Brave Shields
              </a>{' '}
              in order to record!
            </span>
          )}
        </div>
      </>
    )
  }

  return (
    <div id="recording-section" className={styles.page}>
      {displayDowloadForm ? downloadRecordingForm() : recordVideoForm()}
    </div>
  )
}

function formatDuration(seconds: number) {
  const m = Math.floor(seconds / 60)
  const ss = (seconds % 60).toString().padStart(2, '0')
  return `${m}:${ss}`
}
