import { useEffect, useState } from 'react'
import { useMoralis } from 'react-moralis'
import ReactTooltip from 'react-tooltip'

import { useAuthService } from 'service/useAuthService'
import { ellipsizeAddress } from 'utils/formatting'

import { ReactComponent as CopySvg } from 'assets/icons/copy.svg'
import { ReactComponent as SignOutSvg } from 'assets/icons/sign-out.svg'

import styles from './account.module.scss'

const copyTooltipId = 'account-copy-tooltip'
const signOutTooltipId = 'account-sign-out-tooltip'

export const AccountView = () => {
  const { signOut } = useAuthService()
  const { isAuthenticated, user } = useMoralis()
  const [addressCopied, setAddressCopied] = useState(false)

  const copyAddress = () => {
    navigator.clipboard.writeText(user.attributes.accounts[0])
    setAddressCopied(true)
  }

  // Reset's 'Copied!' state after 3s
  useEffect(() => {
    let copiedTimer: NodeJS.Timeout

    if (addressCopied) {
      copiedTimer = setTimeout(() => {
        setAddressCopied(false)
      }, 3000)
    }
    return () => clearTimeout(copiedTimer)
  }, [addressCopied])

  const disconnect = () => {
    console.log('Signing out...')
    signOut()
      .then(() => console.log('Signed out..'))
      .catch(error => console.log('Error signing out', error))
  }

  return (
    <div className={styles.container}>
      {isAuthenticated && (
        <>
          <div className={styles.spacer} />
          <div className={styles.accountInfo} data-tip data-for={copyTooltipId} onClick={copyAddress}>
            <p className={styles.title}>Your Account</p>
            <div className={styles.address}>
              <CopySvg width="100%" height={11} />
              <span>{ellipsizeAddress(user.attributes.accounts[0])}</span>
            </div>
          </div>
          <ReactTooltip id={copyTooltipId} place="top" effect="solid" type="dark">
            {addressCopied ? 'Copied!' : 'Copy'}
          </ReactTooltip>

          <div className={styles.signOut} data-tip data-for={signOutTooltipId} onClick={disconnect}>
            <SignOutSvg width={27} height="100%" />
          </div>
          <ReactTooltip id={signOutTooltipId} place="top" effect="solid" type="dark">
            Disconnect
          </ReactTooltip>
        </>
      )}
    </div>
  )
}
