export class BackgroundColorOptions {
  hex: string
  public constructor(hex: string) {
    this.hex = hex
  }

  static readonly pink = new BackgroundColorOptions('#f78da7')
  static readonly magenta = new BackgroundColorOptions('#eb144c')
  static readonly red = new BackgroundColorOptions('#c00000')
  static readonly orange = new BackgroundColorOptions('#ff6900')
  static readonly gold = new BackgroundColorOptions('#fcb900')
  static readonly green = new BackgroundColorOptions('#28cd41')
  static readonly lightGreen = new BackgroundColorOptions('#7bdcb5')
  static readonly lightBlue = new BackgroundColorOptions('#8ed1fc')
  static readonly blue2 = new BackgroundColorOptions('#337bf6')
  static readonly blue = new BackgroundColorOptions('#1d5adc')
  static readonly purple = new BackgroundColorOptions('#9900ef')
  static readonly grey = new BackgroundColorOptions('#abb8c3')
  static readonly tan = new BackgroundColorOptions('#E2D4B7')
  static readonly white = new BackgroundColorOptions('#ffffff')
  static readonly black = new BackgroundColorOptions('#000000')

  static all: BackgroundColorOptions[] = [
    BackgroundColorOptions.pink,
    BackgroundColorOptions.magenta,
    BackgroundColorOptions.red,
    BackgroundColorOptions.orange,
    BackgroundColorOptions.gold,
    BackgroundColorOptions.green,
    BackgroundColorOptions.lightGreen,
    BackgroundColorOptions.lightBlue,
    BackgroundColorOptions.blue2,
    BackgroundColorOptions.blue,
    BackgroundColorOptions.purple,
    BackgroundColorOptions.grey,
    BackgroundColorOptions.white,
    BackgroundColorOptions.tan,
    BackgroundColorOptions.black
  ]
}
