import cx from 'classnames'

import styles from './button.module.scss'

export type ButtonStyle = 'primary' | 'secondary'
export type ButtonSize = 'large' | 'medium'

type Props = {
  title: string
  onClick?: () => void
  style?: ButtonStyle
  size?: ButtonSize
  className?: string
  disabled?: boolean
}

const styleClass = (style: ButtonStyle) => {
  switch (style) {
    case 'secondary':
      return styles.secondary
    case 'primary':
    default:
      return styles.primary
  }
}

const sizeClass = (size: ButtonSize) => {
  switch (size) {
    case 'medium':
      return styles.medium
    case 'large':
    default:
      return styles.large
  }
}

export const Button = ({ title, onClick, style = 'primary', size = 'large', className, disabled }: Props) => {
  return (
    <button
      className={cx(className, styles.button, styleClass(style), sizeClass(size))}
      onClick={onClick}
      disabled={disabled}
    >
      {title}
    </button>
  )
}
