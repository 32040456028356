import { Spinner } from './spinner'

import styles from './avatarLoadingOverlay.module.scss'

export function AvatarLoadingOverlay() {
  return (
    <div className={styles.container}>
      <Spinner size={40} />
    </div>
  )
}
