import { useContext } from 'react'
import { Popover } from '@headlessui/react'

import { ReactComponent as ConnectWalletSvg } from 'assets/icons/connect-wallet.svg'
import { Button } from 'components/shared/button'
import { AnimatedPopover } from 'components/popovers/animatedPopover'
import { WalletOptionsView } from 'components/popovers/walletOptions/walletOptions'
import { WidgetContext } from 'state/WidgetContext'

import styles from './connectWallet.module.scss'

export const ConnectWalletView = () => {
  const { nftCollection } = useContext(WidgetContext)

  let message = 'Use your avatars!'
  let marketplaceTitle = 'Marketplace'
  let marketplaceUrl = 'https://opensea.io'

  if (nftCollection) {
    message = `Use your ${nftCollection.namePlural}!`
    marketplaceTitle = `${nftCollection.namePlural} Marketplace`
    marketplaceUrl = nftCollection.marketplaceUrl
  }

  return (
    <Popover className={styles.container}>
      {/* <div className={styles.image}>
        <ConnectWalletSvg width="100%" height="100%" />
      </div> */}
      {/* <p className={styles.message}>{message}</p> */}
      <Popover.Button className={styles.popoverButton} as="div">
        <Button title="Connect Wallet" style="secondary" size="medium" />
      </Popover.Button>
      {/* <p className={styles.link}>
        <a href={marketplaceUrl} target="_blank" rel="noopener noreferrer">
          {marketplaceTitle}
        </a>
      </p> */}

      <AnimatedPopover>
        <WalletOptionsView />
      </AnimatedPopover>
    </Popover>
  )
}
