export type MoralisNftInfo = {
  // Types provided by Moralis
  token_address: string
  token_id: string
  contract_type: string
  owner_of: string
  block_number: string
  block_number_minted: string
  token_uri?: string | undefined
  metadata?: string | undefined
  synced_at?: string | undefined
  amount?: string | undefined
  name: string
  symbol: string
}

export type NftInfo = MoralisNftInfo & {
  keyId: string

  // metadata string is parsed as JSON when received and stored here
  metaObj?: Record<string, any> | undefined
}

export const parseNftInfo = (nft: MoralisNftInfo) => {
  const keyId = `${nft.token_address}__${nft.token_id}`

  let metaObj = undefined

  try {
    if (nft.metadata) {
      metaObj = JSON.parse(nft.metadata)
    }
  } catch {
    console.warn('Error parsing nft metadata. value: ', nft.metadata)
  }

  return {
    ...nft,
    keyId,
    metaObj
  }
}
