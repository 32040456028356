import styles from './sideBar.module.scss'

type Props = {
  heading: string
  children: JSX.Element
  visible: boolean
}

export function SideBar({ heading, visible, children }: Props) {
  return (
    <div className={styles.sideBar} style={{ display: visible ? null : 'none' }}>
      <div className={styles.headingWrapper}>
        <h2 className={styles.heading}>{heading}</h2>
      </div>
      {children}
    </div>
  )
}
