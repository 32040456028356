import { ChangeEvent, FormEvent, useContext, useEffect, useState } from 'react'

import { useAuthService } from 'service/useAuthService'
import { UserRole } from 'service/types'
import { WidgetContext } from 'state/WidgetContext'

import styles from './header.module.scss'

export const HeaderView = () => {
  const context = useContext(WidgetContext)
  const { authorizeWithCryptoService } = useAuthService()

  // This stores the current value of the Input as the user types.
  // The Context holds the actual Set value
  const [testingAddrInput, setTestingAddrInput] = useState<string>(context.testingAddress ?? '')

  useEffect(() => {
    setTestingAddrInput(context.testingAddress ?? '')
  }, [context.testingAddress])

  const inputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTestingAddrInput(e.target.value)
  }

  const onFormSubmit = (e: FormEvent) => {
    e.preventDefault()

    const address = testingAddrInput.trim()

    // This will generate a new auth token including the testing address added
    // as long as the server verifies the user as an admin
    authorizeWithCryptoService(address).catch(error => {
      console.error('Error authorizing as admin', error)
      window.alert('Authorization error. Check logs')
    })
  }

  return (
    <div className={styles.header}>
      <h1>{context.currentAvatar?.displayName ?? context.nftCollection?.name ?? 'HALLWAY AVATARS'}</h1>
      {context.userRole === UserRole.admin && (
        <form className={styles.testAddress} onSubmit={onFormSubmit}>
          <div>
            <label>Testing Address</label>
          </div>
          <input name="testingAddress" type="text" onChange={inputChange} value={testingAddrInput}></input>
          <button type="submit" className="btn btn-xs btn-primary" onSubmit={onFormSubmit}>
            Set
          </button>
        </form>
      )}
    </div>
  )
}
