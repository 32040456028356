import { isMobile } from 'react-device-detect'
import cx from 'classnames'

import { EthereumProvider, ProviderNotFoundError, useAuthService } from 'service/useAuthService'

import metamaskIcon from 'assets/logos/metamask.png'
import coinbaseIcon from 'assets/logos/coinbase-wallet.png'
import walletConnectIcon from 'assets/logos/wallet-connect.jpeg'

import styles from './walletOptions.module.scss'

const wallets: WalletInfo[] = [
  {
    id: 'metamask',
    name: 'MetaMask',
    icon: metamaskIcon,
    enabled: true
  },
  {
    id: 'walletConnect',
    name: 'WalletConnect',
    icon: walletConnectIcon,
    enabled: true
  },
  {
    id: 'coinbase',
    name: 'Coinbase Wallet',
    icon: coinbaseIcon,
    enabled: true
  }
]

export const WalletOptionsView = () => {
  const { signInWithMetaMask, signInWithWalletConnect, signInWithCoinbase } = useAuthService()

  const connectWallet = (provider: EthereumProvider) => {
    switch (provider) {
      case 'metamask':
        return signInWithMetaMask()
      case 'walletConnect':
        return signInWithWalletConnect()
      case 'coinbase':
        return signInWithCoinbase()
    }
  }

  const onWalletClicked = (provider: EthereumProvider) => {
    console.log('Signing in...')
    connectWallet(provider)
      .then(() => console.log('Signed in.'))
      .catch(error => {
        if (error instanceof ProviderNotFoundError) {
          console.error(error)
          alert(
            `${provider.toUpperCase()} extension not found on your browser! Please check to see if you have one installed`
          )
        } else if (error.code === 4001) {
          console.log('User denied message signature.')
        } else {
          console.error(`Error connecting wallet. ${error.message}`)
          alert(`Error connecting wallet.\n${error.message}`)
        }
      })
  }

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>Connect Your Wallet</h3>
      <p className={styles.description}>Choose from one of our available wallet providers.</p>
      {wallets.map(wallet => (
        <WalletItem key={wallet.id} {...wallet} onClick={onWalletClicked} />
      ))}
    </div>
  )
}

type WalletInfo = {
  id: EthereumProvider
  name: string
  icon: string
  enabled: boolean
}

type WalletProps = WalletInfo & {
  onClick: (name: EthereumProvider) => void
}

const WalletItem = ({ id, name, icon, enabled, onClick }: WalletProps) => {
  return (
    <div>
      <button
        className={cx(
          styles.walletButton,
          !enabled && styles.disabled,
          // Only display walletconnect option on mobile
          isMobile && (name !== wallets[1].name ? styles.hideWalletOption : '')
        )}
        onClick={() => onClick(id)}
      >
        <img className={styles.walletLogo} src={icon} width="auto" height={24} />
        <div className={styles.nameContainer}>
          <p className={styles.walletName}>{name}</p>
          {!enabled && <label className={styles.comingSoon}>Coming soon</label>}
        </div>
      </button>
    </div>
  )
}
