import { ModelType } from '@quarkworks-inc/avatar-webkit-rendering'

import { AvatarInfo } from 'state/AvatarInfo'

export type NftCollection = {
  identifier: string // Note: keep IDs in lower case
  name: string
  nameSingular: string
  namePlural: string
  symbol: string
  contractAddress: string
  modelType: ModelType
  marketplaceUrl: string
  defaultsAvatars: AvatarInfo[]
}

export const useNftCollections = () => {
  const collections: Record<string, NftCollection> = {
    voids: {
      identifier: 'voids',
      name: 'Voids',
      nameSingular: 'Void',
      namePlural: 'Voids',
      symbol: 'VOID',
      contractAddress: '0xdb55584e5104505a6b38776ee4dcba7dd6bb25fe',
      modelType: 'void',
      marketplaceUrl: 'https://opensea.io/collection/visitors-of-imma-degen',
      defaultsAvatars: [
        {
          keyId: 'void__3157',
          displayName: 'VOID #3157',
          displayNameShort: '#3157',
          modelType: 'void',
          glbUrl: 'https://hallway-private.nyc3.cdn.digitaloceanspaces.com/avatars/_defaults/voids/void_3157.glb',
          thumbnailUrl:
            'https://hallway-private.nyc3.cdn.digitaloceanspaces.com/avatars/_defaults/voids/void_3157_thumbnail.jpg'
        }
      ]
    },
    chibs: {
      identifier: 'chibs',
      name: 'Chibs',
      nameSingular: 'Chib',
      namePlural: 'Chibs',
      symbol: 'CHIBIAPE',
      contractAddress: '0xc49a9ab342b6ea66792d4110e9ca0ab36e3a5674',
      modelType: 'chib',
      marketplaceUrl: 'https://opensea.io/collection/chibi-apes',
      defaultsAvatars: [
        {
          keyId: 'hallway__chib',
          modelType: 'chib',
          displayName: 'Chib #1',
          displayNameShort: 'Chib #1',
          glbUrl: 'https://hallway-public.nyc3.cdn.digitaloceanspaces.com/models/1.glb',
          thumbnailUrl: 'https://hallway-public.nyc3.cdn.digitaloceanspaces.com/thumbnails/1.webp'
        }
      ]
    }
  }

  return {
    collections: {
      ...collections,
      all: Object.values(collections).map(val => val)
    }
  }
}
