import React from 'react'
import ReactDOM from 'react-dom'
import { MoralisProvider } from 'react-moralis'

import { config } from 'config'
import { WidgetProvider } from 'state/WidgetProvider'

import { AvatarWidget } from './widget'

import './index.css'

ReactDOM.render(
  <React.StrictMode>
    <MoralisProvider serverUrl={config.moralis.serverUrl} appId={config.moralis.appId}>
      <WidgetProvider>
        <AvatarWidget />
      </WidgetProvider>
    </MoralisProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
