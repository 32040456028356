import { ModelType } from '@quarkworks-inc/avatar-webkit-rendering'
import produce from 'immer'
import { useState } from 'react'

import { NftCollection, useNftCollections } from 'service/useNftCollections'

import { hallwayDefaultAvatars } from './AvatarInfo'
import { WidgetContext, WidgetContextType, WidgetContextWriteBlock } from './WidgetContext'

export const WidgetProvider = ({ children }) => {
  const widgetParams = new URLSearchParams(window.location.search)
  const apiToken = widgetParams.get('apiToken')
  const avatarType = widgetParams.get('type') as ModelType
  const avatarUrl = widgetParams.get('url')
  const collection = widgetParams.get('collection')
  const walletAddress = widgetParams.get('walletAddress')

  const { collections } = useNftCollections()

  if (!apiToken) {
    console.error('Invalid api token. "apiToken" must be given as a query parameter')
  }

  let nftCollection: NftCollection

  if (collection) {
    nftCollection = collections[collection.toLowerCase()]
    if (!nftCollection) {
      console.error(`Collection type not found: '${collection}'`)
    }
  }

  const defaultAvatars = nftCollection?.defaultsAvatars ?? hallwayDefaultAvatars

  const [context, setContext] = useState<WidgetContextType>({
    // Widget params
    params: { apiToken, avatarType, avatarUrl, collection, walletAddress },
    nftCollection,

    // Auth stuff
    sessionToken: null,
    userRole: null,
    testingAddress: null,

    // Nft Data
    defaultAvatars,
    avatars: defaultAvatars,
    currentAvatar: defaultAvatars[0],
    glbIsLoading: false,

    // Recording
    recordingDuration: -1,

    // Streaming
    oscState: 'closed',

    // State updates are done immutably using immer produce (write) block
    // See implementation in WidgetProvider
    update: (writeBlock: WidgetContextWriteBlock) => {
      setContext(currentContext => produce(currentContext, writeBlock))
    }
  })

  return <WidgetContext.Provider value={context}>{children}</WidgetContext.Provider>
}
