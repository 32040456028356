import { BlendShapeKey, BlendShapeKeys } from '@quarkworks-inc/avatar-webkit'

export class FaceItKeys {
  public static readonly sorted: BlendShapeKey[] = [
    BlendShapeKeys.browInnerUp,
    BlendShapeKeys.browDown_L,
    BlendShapeKeys.browDown_R,
    BlendShapeKeys.browOuterUp_L,
    BlendShapeKeys.browOuterUp_R,
    BlendShapeKeys.eyeLookUp_L,
    BlendShapeKeys.eyeLookUp_R,
    BlendShapeKeys.eyeLookDown_L,
    BlendShapeKeys.eyeLookDown_R,
    BlendShapeKeys.eyeLookIn_L,
    BlendShapeKeys.eyeLookIn_R,
    BlendShapeKeys.eyeLookOut_L,
    BlendShapeKeys.eyeLookOut_R,
    BlendShapeKeys.eyeBlink_L,
    BlendShapeKeys.eyeBlink_R,
    BlendShapeKeys.eyeSquint_L,
    BlendShapeKeys.eyeSquint_R,
    BlendShapeKeys.eyeWide_L,
    BlendShapeKeys.eyeWide_R,
    BlendShapeKeys.cheekPuff,
    BlendShapeKeys.cheekSquint_L,
    BlendShapeKeys.cheekSquint_R,
    BlendShapeKeys.noseSneer_L,
    BlendShapeKeys.noseSneer_R,
    BlendShapeKeys.jawOpen,
    BlendShapeKeys.jawForward,
    BlendShapeKeys.jawLeft,
    BlendShapeKeys.jawRight,
    BlendShapeKeys.mouthFunnel,
    BlendShapeKeys.mouthPucker,
    BlendShapeKeys.mouthLeft,
    BlendShapeKeys.mouthRight,
    BlendShapeKeys.mouthRollUpper,
    BlendShapeKeys.mouthRollLower,
    BlendShapeKeys.mouthShrugUpper,
    BlendShapeKeys.mouthShrugLower,
    BlendShapeKeys.mouthClose,
    BlendShapeKeys.mouthSmile_L,
    BlendShapeKeys.mouthSmile_R,
    BlendShapeKeys.mouthFrown_L,
    BlendShapeKeys.mouthFrown_R,
    BlendShapeKeys.mouthDimple_L,
    BlendShapeKeys.mouthDimple_R,
    BlendShapeKeys.mouthUpperUp_L,
    BlendShapeKeys.mouthUpperUp_R,
    BlendShapeKeys.mouthLowerDown_L,
    BlendShapeKeys.mouthLowerDown_R,
    BlendShapeKeys.mouthPress_L,
    BlendShapeKeys.mouthPress_R,
    BlendShapeKeys.mouthStretch_L,
    BlendShapeKeys.mouthStretch_R,
    BlendShapeKeys.tongueOut
  ]
}
