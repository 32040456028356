import { useContext } from 'react'
import { Popover } from '@headlessui/react'

import { ReactComponent as WarningSvg } from 'assets/icons/warning.svg'
import { Button } from 'components/shared/button'
import { WidgetContext } from 'state/WidgetContext'
import { WidgetError } from 'service/types'

import styles from './error.module.scss'

type Props = {
  error: WidgetError
}

export const ErrorView = ({ error }: Props) => {
  const widgetContext = useContext(WidgetContext)

  return (
    <Popover className={styles.container}>
      <WarningSvg />

      <p className={styles.loaderText}>{error.title}</p>
      <p className={styles.loaderSubText}>
        <div dangerouslySetInnerHTML={error.messageHTML} />
      </p>
      <Button
        title="Okay"
        className={styles.popoverButton}
        onClick={() => {
          widgetContext.update(state => {
            state.error = undefined
          })
        }}
      />
    </Popover>
  )
}
